/* eslint-disable react-hooks/rules-of-hooks */

'use client';

import { cva } from 'class-variance-authority';
import { forwardRef, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { useMergedRef } from '@/hooks/use-merged-ref';

import { UnstyledButton } from '../unstyled-button';

const handleSelect: React.ReactEventHandler<HTMLInputElement> = (event) => {
  const currentValue = event.currentTarget.value;
  event.currentTarget.setSelectionRange(currentValue.length, currentValue.length);
};

const charVariants = cva(
  'inline-flex w-10 items-center justify-center rounded-lg border border-grey-mid bg-white px-3.5 py-4 text-center text-lg font-medium text-black shadow outline-none transition-colors duration-150 ease-linear disabled:bg-grey-light disabled:text-grey-tertiary',
  {
    variants: {
      inactive: {
        true: 'bg-grey-light text-grey-tertiary',
      },
      selected: {
        true: 'border-blue shadow-field',
      },
    },
  },
);

type ValidChars = 'alphanumeric' | 'alpha' | 'numeric';

export interface OneTimeCodeInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  value?: string;
  length?: number;
  validChars?: ValidChars;
  capitalize?: boolean;
  error?: boolean;
  changed?: boolean;
  onEnter?: () => void;
}

export const OneTimeCodeInput = forwardRef<HTMLInputElement, OneTimeCodeInputProps>(
  (
    {
      value: valueProp,
      length = 6,
      validChars = 'numeric',
      capitalize = true,
      error,
      changed,
      placeholder = '',
      disabled,
      autoComplete = 'off',
      hidden,
      className,
      onBlur,
      onChange,
      onEnter,
      onFocus,
      ...restProps
    },
    ref,
  ) => {
    const [isActive, setActive] = useState(false);
    const [localValue, setLocalValue] = useState('');
    const value = valueProp ?? localValue;
    const inputRef = useRef<HTMLInputElement | null>(null);

    const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
      let newValue = event.currentTarget.value.replaceAll(/\s/g, '');

      switch (validChars) {
        case 'alpha': {
          const re = new RegExp(`^[a-zA-Z]{0,${length}}$`);
          if (re.test(newValue)) {
            if (capitalize) {
              newValue = [...newValue].map((v) => v.toUpperCase()).join('');
            }
            event.currentTarget.value = newValue;
            setLocalValue(newValue);
            onChange?.(event);
          }
          break;
        }

        case 'numeric': {
          const re = new RegExp(`^\\d{0,${length}}$`);
          if (re.test(newValue)) {
            event.currentTarget.value = newValue;
            setLocalValue(newValue);
            onChange?.(event);
          }
          break;
        }

        case 'alphanumeric': {
          const re = new RegExp(`^[0-9a-zA-Z]{0,${length}}$`);
          if (re.test(newValue)) {
            if (capitalize) {
              newValue = [...newValue].map((v) => v.toUpperCase()).join('');
            }
            event.currentTarget.value = newValue;
            setLocalValue(newValue);
            onChange?.(event);
          }
          break;
        }

        default: {
          break;
        }
      }
    };

    const handleClick: React.MouseEventHandler<HTMLButtonElement> = () => {
      inputRef.current?.focus();
    };

    const handleBlur: React.FocusEventHandler<HTMLInputElement> = (event) => {
      setActive(false);
      onBlur?.(event);
    };

    const handleFocus: React.FocusEventHandler<HTMLInputElement> = (event) => {
      setActive(true);
      onFocus?.(event);
    };

    const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
      if (['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'].includes(event.key)) {
        event.preventDefault();
      }
      if (event.key === 'Enter') {
        onEnter?.();
      }
    };

    if (hidden) {
      return null;
    }

    return (
      <div className={twMerge('relative', className)}>
        <input
          type="text"
          inputMode={validChars === 'numeric' ? 'numeric' : 'text'}
          ref={useMergedRef(inputRef, ref)}
          value={value}
          minLength={length}
          maxLength={length}
          autoCapitalize={capitalize ? 'characters' : 'none'}
          autoCorrect="off"
          autoComplete={autoComplete}
          spellCheck="false"
          disabled={disabled}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          onKeyDown={handleKeyDown}
          onSelect={handleSelect}
          className="absolute inset-0 border-0 border-none border-transparent bg-transparent text-transparent caret-transparent outline-none selection:bg-transparent read-only:cursor-default disabled:cursor-not-allowed"
          {...restProps}
        />
        <div className="flex h-[52px] gap-x-2">
          {Array.from({ length }).map((_, index) => (
            <UnstyledButton
              key={index}
              disabled={disabled}
              onClick={handleClick}
              className={charVariants({
                selected:
                  value.length === index ||
                  (value.length === index + 1 && index + 1 === length && isActive),
                inactive: value.length < index,
              })}
            >
              {value?.at(index) ?? placeholder}
            </UnstyledButton>
          ))}
        </div>
      </div>
    );
  },
);

OneTimeCodeInput.displayName = 'OneTimeCodeInput';
