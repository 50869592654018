'use client';

import * as LabelPrimitive from '@radix-ui/react-label';
import { Slot, type SlotProps } from '@radix-ui/react-slot';
import { createContext, forwardRef, useContext, useId } from 'react';
import {
  Controller,
  type ControllerProps,
  type FieldPath,
  type FieldValues,
  useFormContext,
} from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

type FormFieldContextValue<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
  name: TName;
};

type FormItemContextValue = {
  id: string;
};

type FormFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = ControllerProps<TFieldValues, TName>;

export const FormFieldContext = createContext<FormFieldContextValue>({} as FormFieldContextValue);
export const FormItemContext = createContext<FormItemContextValue>({} as FormItemContextValue);

export const FormField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  props: FormFieldProps<TFieldValues, TName> & { hidden?: boolean },
) => {
  const { name, hidden, ...restProps } = props;

  if (hidden) {
    return null;
  }

  return (
    <FormFieldContext.Provider value={{ name }}>
      <Controller name={name} {...restProps} />
    </FormFieldContext.Provider>
  );
};

export const FormItem = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, hidden, ...restProps }, ref) => {
    const id = useId();

    if (hidden) {
      return null;
    }

    return (
      <FormItemContext.Provider value={{ id }}>
        <div ref={ref} className={twMerge('space-y-2', className)} {...restProps} />
      </FormItemContext.Provider>
    );
  },
);

export const useFormField = () => {
  const fieldContext = useContext(FormFieldContext);
  const itemContext = useContext(FormItemContext);
  const { formState, getFieldState } = useFormContext();
  const fieldState = getFieldState(fieldContext.name, formState);

  if (!fieldContext) {
    throw new Error('useFormField should be used within <FormField>');
  }

  const { name } = fieldContext;
  const { id } = itemContext;

  return {
    id,
    name,
    formItemId: `${id}-form-item`,
    formDescriptionId: `${id}-form-item-description`,
    formMessageId: `${id}-form-item-message`,
    ...fieldState,
  };
};

export const Asterisk = forwardRef<HTMLSpanElement, React.HTMLAttributes<HTMLSpanElement>>(
  ({ hidden, children = '∗', className, ...restProps }, ref) => {
    if (hidden) {
      return null;
    }

    return (
      <span ref={ref} className={twMerge('text-sm text-pink', className)} {...restProps}>
        {children}
      </span>
    );
  },
);

export const FormLabel = forwardRef<
  HTMLLabelElement,
  React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root>
>(({ hidden, className, children, ...restProps }, ref) => {
  const { formItemId } = useFormField();

  if (hidden || !children) {
    return null;
  }

  return (
    <LabelPrimitive.Root
      ref={ref}
      htmlFor={formItemId}
      className={twMerge('text-base font-medium', className)}
      {...restProps}
    >
      {children}
    </LabelPrimitive.Root>
  );
});

export const FormDescription = forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ hidden, className, children, ...restProps }, ref) => {
  const { formDescriptionId } = useFormField();

  if (!children) {
    return null;
  }

  return (
    <p
      ref={ref}
      id={formDescriptionId}
      className={twMerge('text-sm font-medium text-blue', className)}
      {...restProps}
    >
      {children}
    </p>
  );
});

export const FormMessage = forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ hidden, className, children, ...restProps }, ref) => {
  const { error, formMessageId } = useFormField();
  const errorMessage = error ? String(error.message) : children;

  if (hidden || !errorMessage) {
    return null;
  }

  return (
    <p
      ref={ref}
      id={formMessageId}
      className={twMerge('text-sm font-medium text-pink', className)}
      {...restProps}
    >
      {errorMessage}
    </p>
  );
});

export const FormGroup = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ hidden, className, children, ...restProps }, ref) => {
    if (hidden || !children) {
      return null;
    }

    return (
      <div
        ref={ref}
        className={twMerge('grid grid-cols-1 gap-y-9 sm:grid-cols-2 sm:gap-x-3.5', className)}
        {...restProps}
      >
        {children}
      </div>
    );
  },
);

export const FormControl = forwardRef<HTMLDivElement, SlotProps & { error?: boolean }>(
  (props, ref) => {
    const { error, formItemId, formDescriptionId, formMessageId } = useFormField();

    return (
      <Slot
        ref={ref}
        id={formItemId}
        error={Boolean(error)}
        aria-describedby={error ? `${formDescriptionId} ${formMessageId}` : `${formDescriptionId}`}
        aria-invalid={Boolean(error)}
        {...props}
      />
    );
  },
);

export const FormHelper = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  (props, ref) => {
    const { hidden, children, className, ...restProps } = props;

    if (hidden) {
      return null;
    }

    if (!children) {
      return (
        <div
          ref={ref}
          className={twMerge('flex w-full gap-x-4 empty:hidden', className)}
          {...restProps}
        >
          <FormMessage />
        </div>
      );
    }

    return (
      <div ref={ref} className={twMerge('space-y-1 empty:hidden', className)} {...restProps}>
        <FormDescription>{children}</FormDescription>
        <FormMessage />
      </div>
    );
  },
);

Asterisk.displayName = 'Asterisk';
FormField.displayName = 'FormField';
FormItem.displayName = 'FormItem';
FormLabel.displayName = 'FormLabel';
FormDescription.displayName = 'FormDescription';
FormMessage.displayName = 'FormMessage';
FormControl.displayName = 'FormControl';
FormHelper.displayName = 'FormHelper';
FormGroup.displayName = 'FormGroup';

export { FormProvider as Form } from 'react-hook-form';
