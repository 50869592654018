import { zodResolver } from '@hookform/resolvers/zod';
import { ExternalLinkIcon } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { useSnapshot } from 'valtio';
import { z } from 'zod';

import { Button } from '@/components/button';
import { Form, FormControl, FormField, FormHelper, FormItem } from '@/components/form';
import { OneTimeCodeInput } from '@/components/one-time-code-input';
import { userManager } from '@/models/user';

import { useAnswerImpersonation } from './hooks/answer-impersonation';
import { impersonationFormManager } from './model';

export const ImpersonationForm: React.FC = () => {
  const { user } = useSnapshot(userManager);
  const { username, sessionToken } = useSnapshot(impersonationFormManager);
  const { mutate, isPending } = useAnswerImpersonation();

  const form = useForm({
    mode: 'onSubmit',
    defaultValues: {
      answer: '',
    },
    resolver: zodResolver(
      z.object({
        answer: z.string().min(1, 'Required field'),
      }),
    ),
  });

  const handleSubmit = form.handleSubmit(({ answer }) => {
    mutate({
      username,
      sessionToken,
      answer,
    });
  });

  return (
    <Form {...form}>
      <form id="impersonationForm" onSubmit={handleSubmit} className="space-y-9">
        <p className="text-xl">
          We’ve sent a verification code to <strong>{user?.email}</strong>
        </p>
        <FormField
          name="answer"
          control={form.control}
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <OneTimeCodeInput
                  {...field}
                  autoFocus
                  disabled={isPending}
                  onEnter={handleSubmit}
                  className="flex justify-center"
                />
              </FormControl>
              <FormHelper className="text-center">
                Will impersonate user and open paid app in new tab.
              </FormHelper>
            </FormItem>
          )}
        />
        <Button
          size="lg"
          variant="solid"
          disabled={isPending || !form.formState.isValid}
          onClick={handleSubmit}
        >
          <span>Impersonate</span>
          <ExternalLinkIcon className="size-[18px]" />
        </Button>
      </form>
    </Form>
  );
};
