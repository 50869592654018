import { useMutation } from '@tanstack/react-query';
import { toast } from 'sonner';

import { DialogType, openDialog } from '@/components/dialog';
import { request } from '@/libs/request';

import { saveSessionToken } from '..';

type InitImpersonationParams = {
  username: string;
};

type InitImpersonationResponse = {
  data: {
    sessionToken: string;
  };
} | null;

export const useInitImpersonation = () => {
  return useMutation({
    mutationKey: ['InitImpersonation'],
    mutationFn: async (params: InitImpersonationParams) => {
      const { data } = await request.post<InitImpersonationResponse>('/init-impersonation', params);
      return data?.data ?? null;
    },
    onSuccess: (data) => {
      if (data) {
        saveSessionToken(data.sessionToken);
        openDialog(DialogType.IMPERSONATION, {
          showCloseIcon: true,
        });
      } else {
        toast.error('Failed to init impersonation');
      }
    },
    onError: (error) => {
      toast.error(error instanceof Error ? error.message : 'Failed to init impersonation');
    },
  });
};
