/**
 * Assigns a value to a ref, regardless of its type.
 *
 * @param ref - The ref to assign to.
 * @param value - The value to assign.
 */
export function assignRef<T = unknown>(ref: React.ForwardedRef<T> = null, value: T | null = null) {
  if (typeof ref === 'function') {
    ref(value);
  } else if (typeof ref === 'object' && ref !== null && 'current' in ref) {
    ref.current = value;
  }
}
