import { useCallback } from 'react';

import { assignRef } from './assign-ref';

/**
 * Merges multiple refs into one.
 *
 * @param refs - The refs to merge.
 */
export function mergeRefs<T = unknown>(...refs: React.ForwardedRef<T>[]) {
  return (node: T | null) => {
    refs.forEach((ref) => assignRef(ref, node));
  };
}

/**
 * React hook that merges multiple refs into one.
 *
 * @param refs - The refs to merge.
 */
export function useMergedRef<T = unknown>(...refs: React.ForwardedRef<T>[]) {
  return useCallback(mergeRefs(...refs), refs); // eslint-disable-line react-hooks/exhaustive-deps
}
