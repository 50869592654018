import { proxy } from 'valtio';
import { devtools } from 'valtio/utils';

const initialState = {
  username: '',
  sessionToken: '',
};

export const impersonationFormManager = proxy(initialState);

devtools(impersonationFormManager, {
  name: 'Impersonation Form',
  enabled: false,
});

export const resetImpersonationForm = () => {
  Object.assign(impersonationFormManager, initialState);
};

export const saveUsername = (username: string) => {
  impersonationFormManager.username = username;
};

export const saveSessionToken = (sessionToken: string) => {
  impersonationFormManager.sessionToken = sessionToken;
};
